import { Outlet } from "react-router-dom";
import Header from "../components/Header";

const Layout = ({ station }) => {
  return (
    <>
      <Header station={station} />
      <main className="content fccc">
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
