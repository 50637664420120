export const getDateAfterDays = (date, numberOfDays) => {
  return new Date(date.getTime() + numberOfDays * 24 * 60 * 60 * 1000);
};

export const getDateFormatted = (date) => {
  return `${date.getFullYear()}/${
    date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
  }/${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()}`;
};

export const getDatetimeFormatted = (date) => {
  return `${date.getFullYear()}/${
    date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
  }/${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()} ${
    date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()
  }:${date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()}`;
};

export const getDatetimeFormattedForFrontend = (date) => {
  return `${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()}-${
    date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
  }-${date.getFullYear()} ${
    date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()
  }:${date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()}`;
};

export const getDatetimeFormattedForBackend = (date) => {
  return `${date.getFullYear()}-${
    date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
  }-${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()} ${
    date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()
  }:${date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()}:${
    date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds()
  }`;
};

export const getTimeFormatted = (date) => {
  return `${date.getHours() <= 9 ? "0" + date.getHours() : date.getHours()}:${
    date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()
  }`;
};

export const strToDate = (s) => {
  let [date, time] = s.split("T");
  let [year, month, day] = date.split("-");
  let [hour, minute] = time.split(":");
  return new Date(year, month - 1, day, hour, minute);
};

export const removeItemOnce = (arr, value) => {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const isDate = (date) => {
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};
